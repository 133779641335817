//libraries
import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Frame } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import './styles/index.css';
//components
import AppContext from './AppContext';
import Link from './components/Link';
import Error from './pages/Error';
import UpdateConfirm from './pages/UpdateConfirm';

//pages
const AcceptConditions = React.lazy(() => import('./pages/AcceptConditions'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const VerifyEmail = React.lazy(() => import('./pages/VerifyEmail'));
const ConfirmPlan = React.lazy(() => import('./pages/ConfirmPlan'));
const SelectProductSKUs = React.lazy(() => import('./pages/SelectProductSKUs'));
const CreateButton = React.lazy(() => import('./pages/CreateButton'));
const PreviewButton = React.lazy(() => import('./pages/PreviewButton'));
const Finalize = React.lazy(() => import('./pages/Finalize'));
const ThankYou = React.lazy(() => import('./pages/ThankYou'));

export default class App extends Component {
    // static displayName = App.name;
    constructor(props) {
        super(props);
        this.state = { token: false };
    }

    componentDidMount() {
        const parseParams = () => {
            const params = {};
            const pairs = window.location.search.substring(1).split('&');
            for (let p of pairs) {
                const [key, value] = p.split('=');
                params[key] = value;
            }
            return params;
        };
        const data = parseParams();
        this.setState({
            shop: data.shop,
            owner: data.owner,
            email: data.email,
            step: data.step ? data.step : '/verify-email',
            vintageTheme: data.vintageTheme === '1',
            defaultTab: 0,
            isError: false,
            updateCurrentStep: (step) => this.updateCurrentStep(step),
            setDefaultTab: (defaultTab) => this.setDefaultTab(defaultTab),
            setError: (isError) => this.setError(isError)
        });
    }

    updateCurrentStep(step) {
        this.setState({ step });
    }

    setDefaultTab(defaultTab) {
        this.setState({ defaultTab });
    }

    setError(isError) {
        this.setState({ isError });
    }

    render() {
        if (!this.state.shop && window.location.pathname !== '/jewelry/error') return <></>;
        return (
            <AppContext.Provider value={this.state}>
                <BrowserRouter basename="/jewelry">
                    <AppProvider i18n={enTranslations} linkComponent={Link}>
                        <Routes>
                            <Route
                                exact
                                path="/accept-conditions"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <Frame>
                                            <AcceptConditions />
                                        </Frame>
                                    </React.Suspense>
                                }
                            />
                            <Route
                                exact
                                path="/dashboard"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <Frame>
                                            <Dashboard />
                                        </Frame>
                                    </React.Suspense>
                                }
                            />
                            <Route
                                exact
                                path="/verify-email"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <Frame>
                                            <VerifyEmail />
                                        </Frame>
                                    </React.Suspense>
                                }
                            />
                            <Route
                                exact
                                path="/confirm-plan"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <Frame>
                                            <ConfirmPlan />
                                        </Frame>
                                    </React.Suspense>
                                }
                            />
                            <Route
                                exact
                                path="/select-product-skus"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <Frame>
                                            <SelectProductSKUs />
                                        </Frame>
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="/create-button/:mode"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <CreateButton />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                exact
                                path="/create-button"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <CreateButton />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="/finalize/:mode"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <Frame>
                                            <Finalize />
                                        </Frame>
                                    </React.Suspense>
                                }
                            />
                            <Route
                                exact
                                path="/finalize"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <Frame>
                                            <Finalize />
                                        </Frame>
                                    </React.Suspense>
                                }
                            />
                            <Route
                                exact
                                path="/thank-you"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <Frame>
                                            <ThankYou />
                                        </Frame>
                                    </React.Suspense>
                                }
                            />
                            <Route
                                exact
                                path="/preview-cta"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <PreviewButton />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                exact
                                path="/error"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <Frame>
                                            <Error />
                                        </Frame>
                                    </React.Suspense>
                                }
                            />
                            <Route
                                exact
                                path="/update-confirm"
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <Frame>
                                            <UpdateConfirm />
                                        </Frame>
                                    </React.Suspense>
                                }
                            />
                        </Routes>
                    </AppProvider>
                </BrowserRouter>
            </AppContext.Provider>
        );
    }
}
