import { Card, Stack, Page, TextContainer, TextStyle, List } from '@shopify/polaris';
import { useContext } from 'react';
import AppContext from '../AppContext';
import { API_BASE, SHOPIFY_CONTROLLER } from '../constans'

function UpdateConfirm() {
    const { shop } = useContext(AppContext);

    const SHOPIFY_CONTROLLER_PATH = `${API_BASE}/${SHOPIFY_CONTROLLER}`;

    return (
        <Page>
            <Card sectioned>
                <Card.Section>
                    <TextContainer>
                            <TextStyle variation="strong">Heads Up!</TextStyle>
                            <p>
                                To ensure compliance with Shopify's new policies, all apps, including Tangiblee - Virtual Try-On, must transition away from using ScriptTags on Thank You and Order Status pages by February 1, 2025. This change is essential to prevent any disruptions in service for merchants.
                            </p>
                            <List type="bullet">
                                <List.Item>
                                    Compliance: Starting February 1, 2025, new ScriptTags will be blocked.
                                </List.Item>
                                <List.Item>
                                    Functionality: Updating permissions allows us to use Web Pixels or UI Extensions instead of ScriptTags, ensuring continued app functionality.
                                </List.Item>
                            </List>
                            <p>
                            Thank you for your collaboration!
                            </p>
                    </TextContainer>

                </Card.Section>
                <Card.Section>
                    <Stack spacing="extraLoose" distribution="center">
                        <a
                            className="Polaris-Button Polaris-Button--primary Polaris-Button--fullWidth"
                            fullWidth
                            href={`${SHOPIFY_CONTROLLER_PATH}/UpdateConfirm?shop=${shop}&isConfirm=true`}
                            external
                            target="_top"
                        >
                            Update
                        </a>
                        <a
                            className="Polaris-Button Polaris-Button--destructive  Polaris-Button--fullWidth"
                            fullWidth
                            href={`${SHOPIFY_CONTROLLER_PATH}/UpdateConfirm?shop=${shop}&isConfirm=false`}
                            external
                            target="_top"
                        >
                            Cancel
                        </a>
                    </Stack>
                </Card.Section>
            </Card>
        </Page>
    );
}

export default UpdateConfirm;